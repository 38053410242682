<template>
  <div class="text-left">
    <BaseHeader :title="'Mails'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. At voluptatem maxime nemo vitae similique deserunt, architecto eligendi velit, ut quis illum eos atque doloremque quos labore quaerat illo! Veritatis, rerum?
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("mailing", ["loading", "mailingForm"]),
  },
  methods: {
      ...mapActions('mailing', ['sendMail'])
  },
  mounted() {
      console.log('test');
  }
};
</script>

<style>
</style>